(function ($) {
    jQuery(document).ready(function () {
        // Get the header
        let header = document.getElementById("site-header");
        // Get the offset position of the navbar
        let sticky = header.offsetTop;
        // When the user scrolls the page, execute myFunction
        window.onscroll = function () {
            if (window.pageYOffset > sticky) {
                header.classList.add("is-sticky");
            } else {
                header.classList.remove("is-sticky");
            }
        };

        if ($.fn.tabs) {
            let tabSection = $("[data-tab]");
            tabSection.each(function () {
                $(this).tabs();
            });
        }
        if ($.fn.accordion) {
            let accSection = $("[data-accordion]");
            console.log(accSection);
            accSection.each(function () {
                $accHeader = $(this).attr("data-accordion-header");
                $(this).accordion({
                    header: $accHeader,
                    heightStyle: "content",
                    collapsible: true,
                    beforeActivate: function (event, ui) {
                        // The accordion believes a panel is being opened
                        if (ui.newHeader[0]) {
                            var currHeader = ui.newHeader;
                            var currContent = currHeader.next(".ui-accordion-content");
                            // The accordion believes a panel is being closed
                        } else {
                            var currHeader = ui.oldHeader;
                            var currContent = currHeader.next(".ui-accordion-content");
                        }
                        // Since we've changed the default behavior, this detects the actual status
                        var isPanelSelected = currHeader.attr("aria-selected") == "true";

                        // Toggle the panel's header
                        currHeader.toggleClass("ui-corner-all", isPanelSelected).toggleClass("accordion-header-active ui-state-active ui-corner-top", !isPanelSelected).attr("aria-selected", (!isPanelSelected).toString());

                        // Toggle the panel's icon
                        currHeader.children(".ui-icon").toggleClass("ui-icon-triangle-1-e", isPanelSelected).toggleClass("ui-icon-triangle-1-s", !isPanelSelected);

                        // Toggle the panel's content
                        currContent.toggleClass("accordion-content-active", !isPanelSelected);
                        if (isPanelSelected) {
                            currContent.slideUp();
                        } else {
                            currContent.slideDown();
                        }

                        return false; // Cancels the default action
                    },
                });
            });
        }

        let prev_arrow_markup = '<a class="slider-nav prev-slide" href="#"><i class="ico-angle-left"></i></a>';
        let next_arrow_markup = '<a class="slider-nav next-slide" href="#"><i class="ico-angle-right"></i></a>';
        let heroSlider = $(".hero-slider");
        heroSlider.each(function () {
            $(this).slick({
                arrows: false,
                prevArrow: prev_arrow_markup,
                nextArrow: next_arrow_markup,
                autoplay: false,
                speed: 800,
                fade: true,
                dots: true,
                // centerMode: true,
                // centerPadding: '0',
                focusOnSelect: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            arrows: false,
                        },
                    },
                ],
            });
        });

        let imgSlider = $(".image-slider");
        imgSlider.each(function () {
            $(this).slick({
                arrows: false,
                prevArrow: prev_arrow_markup,
                nextArrow: next_arrow_markup,
                autoplay: false,
                speed: 800,
                fade: true,
                dots: true,
                // centerMode: true,
                // centerPadding: '0',
                focusOnSelect: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            arrows: false,
                        },
                    },
                ],
            });
        });

        let carousel = $(".carousel-slider");
        carousel.each(function () {
            $(this).slick({
                arrows: false,
                prevArrow: prev_arrow_markup,
                nextArrow: next_arrow_markup,
                autoplay: false,
                speed: 800,
                fade: false,
                dots: true,
                focusOnSelect: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 580,
                        settings: {
                            arrows: false,
                            slidesToShow: 1,
                        },
                    },
                ],
            });
        });

        let tourslider = $("#tourslider");
        tourslider.each(function () {
            $(this).slick({
                centerMode: false,
                slidesToShow: 4,
                infinite: false,
                // variableWidth: true,
                // focusOnSelect: true,
                arrows: true,
                dots: false,
                prevArrow: '<button type="button" class="slick-arrow slick-prev" aria-label="Previous"><svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" class="icon__3A1i arrowIcon__1gMn"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.53 2.97c.3.3.3.77 0 1.06L6.56 8l3.97 3.97a.75.75 0 11-1.06 1.06l-4.5-4.5a.75.75 0 010-1.06l4.5-4.5c.3-.3.77-.3 1.06 0z"></path></svg></button>',
                nextArrow: '<button type="button" class="slick-arrow slick-next" aria-label="Next"><svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" class="icon__3A1i arrowIcon__1gMn"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.22 13.03a.75.75 0 010-1.06L9.19 8 5.22 4.03a.75.75 0 011.06-1.06l4.5 4.5c.3.3.3.77 0 1.06l-4.5 4.5c-.3.3-.77.3-1.06 0z"></path></svg></button>',
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: true,
                            centerMode: false,
                            // centerPadding: "40px",
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            arrows: true,
                            centerMode: false,
                            // centerPadding: "40px",
                            slidesToShow: 1,
                        },
                    },
                ],
            });
        });

        $(".side-menu .menu-item-has-children a").click(function () {
            $(this).toggleClass("clicked");
            $(this).next("ul").slideToggle("fast");
        });

        //Sidemenu - Mobile Menu holder
        let sideDrawer = $(".sidedrawer");
        let drawerOpen = $("#burger-menu");
        let drawerClose = $(".drawer-closer");
        let overlay = $(".body-overlay");

        drawerOpen.on("click", function (e) {
            e.preventDefault();
            sideDrawer.toggleClass("sd-open");
            overlay.toggleClass("overlay-show");
        });

        drawerClose.add(overlay).on("click", function (e) {
            e.preventDefault();
            sideDrawer.toggleClass("sd-open");
            overlay.toggleClass("overlay-show");
        });

        let sideNav = $(".side-nav .menu-item-has-children>a");

        sideNav.on("click", function (e) {
            e.preventDefault();
        });

        //Scroll to Top
        $("#scroll-to-top").on("click", function (e) {
            e.preventDefault();
            $("html, body").animate({ scrollTop: 0 });
        });

        $(window).on("scroll", function () {
            if ($(this).scrollTop() > 100) {
                $(".scroll-top-holder").fadeIn("slow");
            } else {
                $(".scroll-top-holder").fadeOut("slow");
            }
        });

        $(".accordion-header").on("click", function (e) {
            e.preventDefault();
            $(this).toggleClass("acc-open");
        });

        $("#close-bar").on("click", function (e) {
            e.preventDefault();
            $("#top-bar-notification").slideUp();
        });

        $(".nav-search, #close-nav-search").on("click", function (e) {
            e.preventDefault();
            let searchbar = $("#search-bar");
            searchbar.toggleClass("show");
            setTimeout(() => {
                searchbar.toggleClass("opaque");
            }, 100);
        });

        // Scroll to div
        $(document).ready(function () {
            $('a[href^="#"]').on("click", function (event) {
                event.preventDefault();
                // sideDrawer.removeClass('sd-open');
                // overlay.removeClass('overlay-show');
                var href = $(this).attr("href");
                var target = $(href);

                if (target.length) {
                    // console.log(target.length);
                    // console.log(href);
                    $("html, body").animate(
                        {
                            scrollTop: target.offset().top - 100,
                        },
                        800
                    );
                } else {
                    window.location.href = "/" + href;
                }
            });
        });
    });
})(jQuery);
